@tailwind base;
@tailwind components;
@tailwind utilities;
/* Adicione este CSS ao seu arquivo CSS global ou dentro de um componente apropriado */
table {
    width: 100%; /* A tabela ocupa toda a largura do container */
    margin: 0 auto; /* Centraliza horizontalmente a tabela */
    border-collapse: collapse; /* Remove espaços entre as bordas das células */
  }
  
  th, td {
    text-align: left; /* Alinha o texto à esquerda */
    padding: 8px; /* Adiciona espaçamento entre o texto e as bordas da célula */
  }
  
  /* Estilos para tabelas responsivas */
  @media screen and (max-width: 768px) {
    /* Para dispositivos móveis */
    table {
      display: block; /* Torna a tabela um elemento de bloco */
      overflow-x: auto; /* Adiciona uma barra de rolagem horizontal se necessário */
    }
  
    th, td {
      display: block; /* Faz com que cada coluna se torne um bloco */
      width: 100%; /* A largura de cada coluna é 100% */
    }
  }